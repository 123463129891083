import React, { useState, useEffect } from "react";
import { About } from "../about";
import { Footer } from "../footer";
import { Main } from "../main";
import { Recomendation } from "../recomendation";
import { Requirement } from "../requirement";
import { FAQ } from "./../faq/index";

export const Container = ({ noCard }) => {
  const [cft, setCFT] = useState("");
  const [tna, setTNA] = useState("");
  const [interest, setInterest] = useState([]);
  //const [noCard, setNoCard] = useState(false);

  /*
    useEffect(() => {
        if (process.env.REACT_APP_NO_CARD_ENV == 1) setNoCard(true)
    }, [])
*/
  return (
    <>
      <Main setGlobalCFT={setCFT} setGlobalTNA={setTNA} interest={interest} setInterest={setInterest} noCard={noCard} />
      <Requirement noCard={noCard} />
      <About />
      <Recomendation />
      <FAQ interest={interest} noCard={noCard} />
      <Footer cft={cft} tna={tna} />
    </>
  );
};
