import React, { useState, useRef, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { Decoration5 } from "../../icon/Decoration5";
import { Illustration } from "./../../icon/Illustration";

const breatheAnimation = keyframes`
 0% { transform: translateY(0); }
 50% { transform: translateY(35px);}
 100% { transform: translateY(0); }
`;

const textanimation = keyframes`
 0% { transform: translateY(80px); opacity: 0; }
 100% { transform: translateY(0); opacity: 1; }
`;

const AboutUsContainer = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 100px;
  max-height: 768px;

  .container {
    flex: 0.35;
    max-width: 450px;
    position: relative;
    height: 100%;
    width: 40%;
    h3,
    p {
      opacity: ${(props) => (props.first ? 1 : 0)};
      animation: ${(props) =>
        props.first
          ? css`
              ${textanimation} 2s linear
            `
          : "red"};
    }
    h3 {
      font-size: 30px;
      margin: 0 0 40px 0;
      color: ${(props) => props.theme.text_black};
      span {
        color: ${(props) => props.theme.purple};
      }
    }
    p {
      margin: 0;
      color: ${(props) => props.theme.gray_two};
    }
    .decoration {
      position: absolute;
      bottom: -70px;
      z-index: -1;
      left: 50px;
    }
  }
  .illustration-container {
    flex: 0.35;
    width: 40%;
    svg {
      width: 550px;
      animation: ${breatheAnimation} 8s ease-in-out infinite;
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    max-height: none;
    padding: 50px 15px;

    .container {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        width: 80%;
        text-align: center;
      }
      p {
        width: 90%;
      }
    }
    .illustration-container {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 300px;
        height: 300px;
      }
    }
  }
`;

export const About = () => {
  const referencia = useRef(null);
  const [visible, setVisible] = useState(false);
  const [first, setFirst] = useState(false);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  };

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (referencia.current) observer.observe(referencia.current);

    return () => {
      if (referencia.current) observer.unobserve(referencia.current);
    };
  }, [referencia, options]);

  useEffect(() => {
    visible && setFirst(true);
  }, [visible]);

  return (
    <AboutUsContainer first={first} ref={referencia} id="nosotros">
      <div className="container">
        <h3>
          Somos la <span>solución</span> que estabas buscando
        </h3>
        <p>
          Nos dedicamos a proveer servicios integrales, brindando soluciones como préstamos al instante, asesoramiento
          legal y contable, descuentos exclusivos, técnicos, reparadores, telemedicina, y mucho más.
        </p>
        <div className="decoration">
          <Decoration5 />
        </div>
      </div>
      <div className="illustration-container">
        <Illustration />
      </div>
    </AboutUsContainer>
  );
};
