import React from "react";
import styled, { css } from "styled-components";
import { StarsIcon } from "./../../icon/StarsIcon";

const CardContainer = styled.div`
  width: 320px;
  height: ${(props) => (props.recomendation ? (props.selected ? "371px" : "350px") : "371px")};
  border-radius: 10px;
  padding: 36px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => (props.selected ? "white" : "#E5E5E5")};
  box-shadow: ${(props) => (props.selected ? "0px 4px 24px 1px rgba(90, 156, 255, 0.18)" : "")};
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (!props.haveAnimation ? 1 : 0)};
  animation: ${(props) =>
    props.haveAnimation
      ? css`
          ${props.animation} 2s ease-out ${props.delay}s 1 normal forwards
        `
      : "red"};
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    .image-container {
      width: 72px;
      height: 72px;
      margin-bottom: 30px;
      img {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    h3 {
      text-align: center;
      font-size: 20px;
      margin: 46px 0 0 0;
      height: 20px;
    }
    p {
      text-align: center;
      font-size: 18px;
      margin: 45px 0 0 0;
    }
  }
  .recomendation {
    width: 100%;
    display: flex;
    justify-content: center;
    h3 {
      text-align: center;
      font-size: 20px;
      margin: 25px 0 0 0;
      height: 20px;
    }
    p {
      text-align: center;
      font-size: 15px;
      margin: 15px 0 0 0;
    }
  }
  .icon-container {
    padding: 18px;
    border-radius: 20px;
    background-color: ${(props) => (props.color ? props.color : "white")};
  }
  @media (max-width: 700px) {
    margin-bottom: 50px;
  }
`;

export const Card = ({
  icon,
  title,
  text,
  color = "#FFF",
  selected = false,
  recomendation = false,
  image,
  delay = 0,
  animation = css``,
  haveAnimation = false,
}) => {
  return (
    <CardContainer
      color={color}
      selected={selected}
      recomendation={recomendation}
      delay={delay}
      animation={animation}
      haveAnimation={haveAnimation}
    >
      <div className="container">
        {recomendation ? (
          <div className="image-container">
            <img src={image} />
          </div>
        ) : (
          <div className="icon-container">{icon}</div>
        )}
      </div>

      <div className={recomendation ? "recomendation" : "container"}>
        <h3>{title}</h3>
      </div>
      <div className={recomendation ? "recomendation" : "container"}>
        <p>{text}</p>
      </div>
    </CardContainer>
  );
};
