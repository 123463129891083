import React, { useState, useRef, useEffect } from "react";
import ReactWhatsapp from "react-whatsapp";
import styled, { css, keyframes } from "styled-components";
import { DecorationIcon } from "../../icon/DecorationIcon";
import logoNoCard from "../../icon/logo3.png";
import logo from "../../icon/logo3.png";
import { DecorationIcon2 } from "../../icon/DecorationIcon2";
import { Box, Button, Slider } from "@mui/material";
import { Decoration3 } from "../../icon/Decoration3";
import { Decoration4 } from "../../icon/Decoration4";
import AppButton from "../AppButton";
import api from "../api/api";

const SliderCustom = styled(Slider)`
  .MuiSlider-valueLabel {
    background-color: transparent !important;
    color: ${(props) => props.theme.text_black};
  }

  .MuiSlider-track {
    background-color: #ffa412;
    border-style: none;
  }

  .MuiSlider-rail {
    background-color: #e5e5e5;
  }

  .MuiSlider-thumb {
    background-color: #ffa412;
    width: 15px;
    height: 15px;
    box-shadow: 0 0 0 0 transparent;

    &:hover {
      box-shadow: 0 0 0 0 transparent;
    }
  }
`;

const textanimation = keyframes`
  0% {
    transform: translateY(80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const MainContainer = styled.div`
  min-height: 100vh;
  max-height: 768px;

  .decoration-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .decoration-container-two {
    position: absolute;
    top: 50%;
    left: 0;
  }

  .section-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    justify-content: space-around;
    padding: 0 50px;

    .section-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40%;
      min-height: 750px;
      opacity: ${(props) => (props.first ? 1 : 0)};
      animation: ${(props) =>
        props.first
          ? css`
              ${textanimation} 2s ease-out
            `
          : "red"};

      img {
        width: 430px;
      }

      h2 {
        font-size: 18px;
        font-weight: 400;
        width: 430px;
        color: ${(props) => props.theme.text_gray};
      }
    }

    .section-selector {
      width: 40%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      min-height: 750px;

      .decoration-container-three {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
      }

      .decoration-container-four {
        position: absolute;
        top: 50px;
        left: 0;
      }

      .modal-scroll {
        margin-left: 60px;
        margin-top: 30px;
        width: 422px;
        border-radius: 15px;
        box-shadow: 0 4px 25px 0 ${(props) => props.theme.shadow_purple};
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 37px 42px;
        z-index: 1;

        h2 {
          margin: 0px;
          font-size: 25px;
        }

        .content-one {
          margin-top: 16px;
          width: 100%;

          .monto {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0;
          }

          .monto-cantidad {
            margin: px 0 30px 0 !important;
            font-weight: 600;
            color: ${(props) => props.theme.text_black};
          }
        }

        .content-two {
          width: 100%;
          margin-top: 10px;

          p {
            font-weight: 700;
            font-size: 18px;
          }

          input {
            width: 100%;
            padding: 15px 18px 17px 18px;
            border-style: none;
            background-color: #f1f1f1;
            border-radius: 10px;
            font-size: 18px;
          }
        }

        .content-three {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex: 1;
          margin-top: 30px;

          .info {
            flex: 0.5;

            h3 {
              font-size: 18px;
              margin: 0;
            }

            p {
              margin: 0;
              font-weight: 700;
              font-size: 25px;
            }
          }
        }

        .button {
          width: 100%;
          height: 41px;
          border-radius: 8px;
          border-style: none;
          font-size: 18px;
          font-weight: 700;
          color: white;
          background-color: #9571fb;
          cursor: pointer;
          padding: 10px 0;

          &:hover {
            background-color: #8d64fd;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    height: 100%;
    max-height: none;
    .section-container {
      flex-direction: column;
      padding: 25vh 10px 30px 10px;
      height: 100%;

      .section-logo {
        min-height: 0px;
        width: 100%;
        margin-bottom: 80px;

        img {
          width: 276px;
        }

        h2 {
          width: 276px;
          font-size: 15px;
        }
      }

      .section-selector {
        align-items: center;

        width: 100%;
        min-height: 0px;

        .modal-scroll {
          margin-left: 0px;
        }
      }
    }
  }
`;

export const Main = ({ interest, noCard, setInterest, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [prevLen, setPrevLen] = useState(0);
  const [alert, setAlert] = useState("");
  const [stateLoan, setStateLoan] = useState({
    amount: 2000,
    installmentOption: 0,
    bin: "",
  });

  useEffect(() => {
    let apiTarget = noCard ? api.getInterestNoCard : api.getInterest;
    setLoading(true);
    apiTarget(
      (interest) => {
        setInterest(interest);
        setStateLoan({
          ...stateLoan,
          installmentOption: interest.length - 1,
          amount: interest[0].maxAllowedAmount * 0.5,
        });
        setLoading(false);
        props.setGlobalCFT(interest[interest.length - 1].cftea);
        props.setGlobalTNA(interest[interest.length - 1].tna);
      },
      () => {
        setLoading(false);
      }
    );
  }, [noCard]);

  const valueLabelFormat = (x) => {
    return `$${x}`;
  };

  const calculatePrice = () => {
    return (
      (stateLoan.amount * interest[stateLoan.installmentOption]?.installmentRate) /
      interest[stateLoan.installmentOption]?.installments
    );
  };

  const handleChangeBIN = (e) => {
    let value = e.target.value;
    console.log(value, value.length >= 6);
    setStateLoan({ ...stateLoan, bin: value });
    if (value.length >= 6) {
      setLoading(true);
      api.getInterestByBIN(
        value,
        (
          //{message, ...res}
          res
        ) => {
          setInterest(res);
          setStateLoan({
            ...stateLoan,
            bin: value,
            installmentOption: res.length - 1,
          });
          setLoading(false);
          setPrevLen(6);
          props.setGlobalCFT(res[res.length - 1].cftea);
          props.setGlobalTNA(res[res.length - 1].tna);
          //setAlert(message)
        },
        () => {
          setLoading(false);
          setAlert("Numero inválido.");
        }
      );
    } else if (prevLen >= 6) {
      setLoading(true);
      api.getInterest(
        (res) => {
          setInterest(res);
          setStateLoan({
            ...stateLoan,
            bin: value,
            installmentOption: res.length - 1,
          });
          props.setGlobalCFT(res[res.length - 1].cftea);
          props.setGlobalTNA(res[res.length - 1].tna);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
      setAlert("");
      setPrevLen(0);
    } else {
      setAlert("");
    }
  };

  const referencia = useRef(null);
  const [visible, setVisible] = useState(false);
  const [first, setFirst] = useState(false);
  const [valuesToSave, setValuesToSave] = useState({
    ammount: 2000,
    installments: 6,
    installmentsAmmount: 1500,
    annualInterest: 50.2,
    fromLanding: true,
  });
  const options = useState({
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };

  // UseEffects

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (referencia.current) observer.observe(referencia.current);

    return () => {
      if (referencia.current) observer.unobserve(referencia.current);
    };
  }, [referencia, options]);

  useEffect(() => {
    visible && setFirst(true);
  }, [visible]);

  const getWhatsAppLink = () => {
    const msg = `¡Hola! Los contacté por la página de Pressto.%0AQuiero obtener un préstamo con tarjeta:%0AMonto: $${
      stateLoan.amount
    }%0ACuotas: ${interest[stateLoan.installmentOption].installments}%0AMuchas gracias. `;
    return `https://api.whatsapp.com/send/?phone=541122867138&text=${msg}&type=phone_number&app_absent=0`;
  };

  return (
    <MainContainer first={first} ref={referencia} id="inicio">
      <div className="decoration-container">
        <DecorationIcon />
      </div>
      <div className="decoration-container-two">
        <DecorationIcon2 />
      </div>
      <div className="section-container">
        <div className="section-logo">
          <img src={logo} alt="logo" />
          <h2 id="description">{"Préstamos y beneficios al instante. ¡Unite al Club!"}</h2>
        </div>
        <div className="section-selector">
          <div className="modal-scroll">
            <h2>¿Cuánto dinero necesitás?</h2>
            <div className="content-one">
              <p className="monto">Monto</p>
              <p className="monto-cantidad">${Number(stateLoan.amount).toLocaleString("de-DE")}</p>
              <Box>
                <SliderCustom
                  aria-label="Always visible"
                  valueLabelFormat={valueLabelFormat}
                  value={stateLoan.amount}
                  valueLabelDisplay="off"
                  min={interest[0]?.minAllowedAmount}
                  max={interest[0]?.maxAllowedAmount}
                  step={1000}
                  onChange={(e) => setStateLoan({ ...stateLoan, amount: e.target.value })}
                />
              </Box>
            </div>
            <div className="content-two">
              <p>Cantidad de cuotas</p>
              {interest?.map((int, i) => {
                return (
                  <AppButton
                    key={i}
                    onClick={() => {
                      setStateLoan({ ...stateLoan, installmentOption: i });
                      props.setGlobalCFT(interest[i].cftea);
                      props.setGlobalTNA(interest[i].tna);
                    }}
                    selected={stateLoan.installmentOption === i}
                  >
                    {int.installments}
                  </AppButton>
                );
              })}
            </div>
            {/*!noCard && (
              <div className="content-two">
                <p>BIN de la Tarjeta</p>
                <input
                  style={{ opacity: stateLoan.bin.length === 0 ? 0.5 : 1 }}
                  type="text"
                  value={stateLoan.bin}
                  placeholder="Primeros 6 dígitos (opcional)"
                  onChange={handleChangeBIN}
                  maxLength={8}
                />
                <p>{alert}</p>
              </div>
            )*/}
            <div className="content-three">
              <div className="info">
                <h3>A pagar por cuota*</h3>
                {loading ? <p>Cargando</p> : <p>${Math.trunc(calculatePrice()).toLocaleString("de-DE")}</p>}
              </div>
            </div>

            {/* <button className='button' style = {{marginTop:10}} onClick={saveInLocalStorage}>
                            Solicitar
                        </button> */}
            <button
              className="button"
              style={{ marginTop: 10 }}
              //onClick={() => (window.location.href = getWhatsAppLink())}
              onClick={() => (window.location.href = "https://plataforma.clubpressto.com.ar")}
            >
              Solicitar
            </button>
          </div>
          <div className="decoration-container-three">
            <Decoration3 />
          </div>
          <div className="decoration-container-four">
            <Decoration4 />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
