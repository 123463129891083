import React, { useState, useRef, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PlusIcon } from "./../../icon/PlusIcon";

const FAQContainer = styled.div`
  padding: 0 100px 40px 100px;
  min-height: 80vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: 700 !important;
  }
  h2 {
    font-size: 40px;
    color: ${(props) => props.theme.text_black};
    margin-bottom: 50px;
    flex: 0.1;
    span {
      color: ${(props) => props.theme.purple};
    }
  }
  .MuiAccordionSummary-gutters {
    flex-direction: row-reverse;
  }
  .MuiPaper-root {
    width: 100%;
    border-radius: 10px;
    padding: 5px 18px 5px 18px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(196, 196, 196, 0.314);
    margin: 10px 0;
    box-shadow: 0px 0px 0px 0px transparent;
    transition: all 0.2s ease-out;
    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 0px 24px 1px #bfbfbf42;
    }
    &::before {
      background-color: transparent;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-right: 20px;
  }
  .MuiTypography-root {
    font-size: 20px;
    font-weight: 500;
  }
  .MuiTypography-h4 {
    font-size: 15px;
    color: ${(props) => props.theme.gray_two};
  }
  @media (max-width: 700px) {
    padding: 0 15px 40px 15px;
    min-height: 100vh;
    height: 100%;
    h2 {
      font-size: 30px;
    }
    .MuiPaper-root {
      margin: 5px 0;
    }
  }
`;

const textanimation = keyframes`
 0% { transform: translateY(80px); opacity: 0; }
 100% { transform: translateY(0); opacity: 1; }
`;

const AccordionAnimated = styled(Accordion)`
  opacity: 0;
  animation: ${(props) =>
    props.first
      ? css`
          ${textanimation} 2s ease-out ${props.delay}s 1 normal forwards
        `
      : "red"};
`;

export const FAQ = ({ interest, noCard }) => {
  const referencia = useRef(null);
  const [visible, setVisible] = useState(false);
  const [first, setFirst] = useState(false);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  };

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (referencia.current) observer.observe(referencia.current);

    return () => {
      if (referencia.current) observer.unobserve(referencia.current);
    };
  }, [referencia, options]);

  useEffect(() => {
    visible && setFirst(true);
  }, [visible]);

  const items = [
    {
      question: "¿Cómo solicito los beneficios de Club Pressto?",
      answer:
        "Para solicitar los beneficios, enviá un correo electrónico a contacto@clubpressto.com.ar, detallando tu número de DNI, Nombre y Apellido. Nuestro equipo de especialistas te asesorará a la brevedad.",
    },

    /*{
      question: "¿Cómo hago para pedir un préstamo?",
      answer:
        "En el cotizador de arriba, seleccionas el monto a solicitar, y la cantidad de cuotas a pagar. Al presionar en el botón SOLICITAR, " +
        (noCard
          ? " te pondrás en contacto con nosotros a través de WhatsApp, donde solicitaremos tus datos."
          : "te redirigirá a la plataforma de clientes donde te registrarás. Una vez registrado, te adherís al servicio Club Pressto, y solicitás el préstamo. (Sujeto a aprobación crediticia)"),
    },*/
    {
      question: "¿Cuáles son los requisitos para solicitar un préstamo?",
      answer:
        "Para solicitar un préstamo, deberás contar con DNI argentino, una cuenta bancaria argentina, una tarjeta de crédito o débito con puntaje crediticio suficiente, no poseer deudas con otras entidades financieras en situación de morosidad, tener ingresos (en blanco) suficientes, y estar adherido a la membresía de Club Pressto. Al solicitar el préstamo, aceptás adherirte a la membresía de Club Pressto, la cual no garantiza el otorgamiento del crédito.",
    },
    {
      question: "¿Cómo calculan mi puntaje crediticio?",
      answer:
        "El puntaje crediticio es analizado por nuestro sistema informático automático en base a varios parámetros, como historial de deuda en otras entidades con situación de morosidad, tasa de aprobación de cobros del BIN de la tarjeta de crédito/débito, informe de actividad económica, historial de cobros de la membresía, e historial de créditos otorgados por Club Pressto.  La aprobación final del préstamo queda sujeta análisis manual donde se verifican los ingresos del solicitante, y se validan todos los datos ingresados.",
    },
    /*{
      question: "¿Cómo recibo el dinero del préstamo?",
      answer: "El dinero te lo enviamos de inmediato por transferencia bancaria.",
    },*/
    {
      question: "¿Cuánto dinero puedo solicitar?",
      answer:
        "El monto máximo que podés solicitar está limitado por tu puntaje crediticio. Los usuarios con puntaje crediticio máximo pueden solicitar hasta $1.500.000. Los usuarios nuevos con bajo puntaje crediticio pueden solicitar hasta $200.000.",
    },
    {
      question: "¿Qué costo tiene membresía?",
      answer: "La membresía tiene un costo mensual de $6.990. Se abona a través de débito automático.",
    },
    {
      question: "¿Cómo solicito la baja de la membresía?",
      answer:
        "Para solicitar la baja del servicio Club Pressto, enviá un mail a baja@clubpressto.com.ar con tu nombre, apellido y DNI. La baja se realiza de forma inmediata.",
    },
    /*{
      question: "¿Puedo solicitar un reembolso de la membresía?",
      answer:
        "Si, podés solicitar el reembolso de las últimas 3 cuotas de tu membresía si no consumiste ningún beneficio de Club Pressto.",
    },*/
  ];

  return (
    <FAQContainer ref={referencia} id="faq">
      <h2>
        <span>Preguntas</span> frecuentes
      </h2>
      {items.map((item, index) => (
        <AccordionAnimated key={index} first={first} delay={index / 5}>
          <AccordionSummary
            expandIcon={<PlusIcon className={"icon"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h1"> {item.question} </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h4"> {item.answer} </Typography>
          </AccordionDetails>
        </AccordionAnimated>
      ))}
    </FAQContainer>
  );
};
